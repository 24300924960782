<template>
  <div id="app" v-responsive="{
      //Border mit abgezogen: 30px
      screensm: el => (el.width <= 576),
      screenmd: el => (el.width > 576 && el.width <= 768),
      screenlg: el => (el.width > 768 && el.width <= 992),
      screenxl: el => (el.width > 992 && el.width <= 1200),
      screenxxl: el => (el.width > 1200)
       }">
    <div class="lightning-wrapper"><img src="./assets/top-light.svg"></div>
    <div class="man-wrapper"><img src="./assets/top-img.svg"></div>
    <div class="app-wrapper">
      <div class="text-wrapper">
        <h2>Oben ist das neue vorn, <br/>werde Dachdecker.</h2>
        <p>Hier findest du alle Infos rund um die Ausbildung zum Dachdecker oder zur Dachdeckerin. Zum Einstieg und
          Aufstieg
          mit Eindrücken von Menschen in diesem großartigen Beruf.</p>
        <a class="button" :href="'https://obenistdasneuevorn.de/?pk_campaign=iframe&pk_source='+betrieb" target="_blank">obenistdasneuevorn.de</a>
      </div>
      <div class="video-wrapper">
        <div class="col-1">
          <div class="video-container">
            <video controls poster="./assets/film_poster.jpg">
              <source src="./assets/oidnv_kampagnenfilm.webm" type="video/webm">
              <source src="./assets/oidnv_kampagnenfilm.mp4" type="video/mp4">
            </video>

            <img class="play-button" src="./assets/film_preview.jpg" @click="playVideo">
            <span id="mark-ausbildung" class="mark">#Ausbildung starten</span>
          </div>
        </div>
        <div class="col-2">
          <div class="arrow-wrap">
            <div class="img-wrap"><img src="./assets/arrows.svg" alt="image-description"></div>
            <h2>Mehr Infos zum <br/>Beruf gibt's hier</h2>
          </div>
          <div class="link-wrapper">
            <div class="social">
              <a href="https://www.instagram.com/obenistdasneuevorn/" target="_blank"><img
                  src="./assets/icon_instagram.svg"></a>
              <a href="https://www.youtube.com/channel/UCI3zkKNf-8IEuoax1goE70Q" target="_blank"><img
                  src="./assets/icon_youtube.svg"></a>
              <a href="https://www.facebook.com/Obenistdasneuevorn" target="_blank"><img
                  src="./assets/icon_facebook.svg"></a>
            </div>
            <a class="button" :href="'https://obenistdasneuevorn.de/?pk_campaign=iframe&pk_source='+betrieb"
               target="_blank">obenistdasneuevorn.de</a>
          </div>
        </div>
      </div>
      <div class="bottom-wrapper">
        <p>Dachdecker-Innungen in Baden-Württemberg</p>
      </div>
    </div>
  </div>
</template>

<script>
import {ResponsiveDirective} from "vue-responsive-components"

export default {
  name: 'app',
  props: {
    betrieb: {
      type: String,
      default: "none"
    }
  },
  directives: {
    responsive: ResponsiveDirective
  },
  data() {
    return {}
  },
  mounted() {
    var linkNode = document.createElement("link");
    linkNode.type = "text/css";
    linkNode.rel = "stylesheet";
    linkNode.href = "//azubicontent.obenistdasneuevorn.de/v1/fonts.css";
    document.head.appendChild(linkNode);

    // window.addEventListener('load', this.adjustArrow);
    // window.addEventListener('resize', this.adjustArrow);
  },
  methods: {
    playVideo: function (event) {
      event.target.previousElementSibling.play()
      event.target.style.visibility = 'hidden';
      document.querySelector('#mark-ausbildung').style.visibility = 'hidden';
    },
    muteVideo: function () {
      if (this.$refs.video.muted == false) {
        this.$refs.video.muted = true;
        this.$refs.mute.classList.remove('icon-volume-high');
        this.$refs.mute.classList.add('icon-volume-mute2');
      } else {
        this.$refs.video.muted = false;
        this.$refs.mute.classList.remove('icon-volume-mute2');
        this.$refs.mute.classList.add('icon-volume-high');
      }
    },
    toggleFullscreen: function () {
      if (this.$refs.video.requestFullscreen) {
        this.$refs.video.requestFullscreen();
      } else if (this.video.mozRequestFullScreen) {
        this.$refs.video.mozRequestFullScreen(); // Firefox
      } else if (this.video.webkitRequestFullscreen) {
        this.$refs.video.webkitRequestFullscreen(); // Chrome and Safari
      }
    },
    updatePlaybackTime: function () {
      let time = this.$refs.video.duration * (this.$refs.seek.value / 100);
      this.$refs.video.currentTime = time;
    },
    updateSeekBar: function () {
      let value = (100 / this.$refs.video.duration) * this.$refs.video.currentTime;
      this.$refs.seek.value = value;
    },
    seekBarMouseDown: function () {
      this.$refs.video.pause();
    },
    seekBarMouseUp: function () {
      this.$refs.video.play();
    },
    volumeBar: function () {
      this.$refs.video.volume = this.$refs.volume.value;
      if (this.$refs.video.volume === 0) {
        this.$refs.mute.classList.remove('icon-volume-high');
        this.$refs.mute.classList.remove('icon-volume-low');
        this.$refs.mute.classList.add('icon-volume-mute2');
      } else if (this.$refs.video.volume < 0.5) {
        this.$refs.mute.classList.remove('icon-volume-high');
        this.$refs.mute.classList.remove('icon-volume-mute2');
        this.$refs.mute.classList.add('icon-volume-low');
      } else {
        this.$refs.mute.classList.remove('icon-volume-mute2');
        this.$refs.mute.classList.remove('icon-volume-low');
        this.$refs.mute.classList.add('icon-volume-high');
      }
    },
    // adjustArrow: function() {
    //   console.log('adjust arrow');
    //   this.$refs.arrow.style.borderTop = this.$refs.bar.clientHeight/2 + 'px solid transparent';
    //   this.$refs.arrow.style.borderBottom = this.$refs.bar.clientHeight/2 + 'px solid transparent';
    // }
  },
}
</script>

<style lang="scss">
$yellow: #FAF3C8;
$blue: #324D75;
$red: #E7392E;
$dark-red: #9A2929;

$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1400px;

//screensm: el => el.width <= 540,
//screenmd: el => (el.width > 540 && el.width <= 720),
//screenlg: el => (el.width > 720 && el.width <= 960),
//screenxl: el => (el.width > 960 && el.width <= 1140),
//screenxxl: el => (el.width > 1140 && el.width <= 1320)


body {
  margin: 0;
}

#app {
  position: relative;
  margin: 0 auto;
  box-sizing: border-box;
  background: url('assets/background.svg');
  background-size: cover;
  overflow: hidden;
  border: 15px solid $yellow;
  width: 100%;

  &.screenxl,
  &.screenxxl {
    @media (orientation: landscape) {
      max-width: 1140px;
    }
  }

  &.screenmd,
  &.screenlg,
  &.screenxl,
  &.screenxxl {
    border: 22px solid $yellow;
  }

  * {
    font-family: 'Calibre';
    font-weight: normal;
    font-size: 16px;
    color: white;
    box-sizing: border-box;
  }


  .lightning-wrapper {
    position: absolute;
    display: none;
    z-index: 0;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &.screenmd .lightning-wrapper,
  &.screenlg .lightning-wrapper {
    display: block;
    max-width: 500px;
    max-height: 500px;
    top: -10%;
    right: -15%;
  }

  &.screenxl .lightning-wrapper,
  &.screenxxl .lightning-wrapper{
    max-width: 500px;
    max-height: 500px;
    top: -8%;
    right: -15%;
    display: block;
  }

  .man-wrapper {
    z-index: 0;
    position: absolute;
    width: 100%;
    bottom: 0%;
    left: 0;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &.screenmd {
    .man-wrapper {
      @media (orientation: portrait) {
        width: 750px;
        right: -25%;
        bottom: -5%;
        left: unset;
      }

      @media (orientation: landscape) {
        position: absolute;
        width: 700px;
        bottom: -3%;
        right: -20%;
        left: unset;
      }
    }

    .video-container {
      width: 90%;
    }
  }

  &.screenlg {
    .man-wrapper {
      @media (orientation: landscape) {
        width: 900px;
        right: -25%;
        bottom: -3%;
        left: unset;
      }
      @media (orientation: portrait) {
        width: 900px;
        right: -25%;
        bottom: -2%;
        left: unset;
      }
    }

    .video-container {
      width: 100%;
    }
  }

  &.screenxl,
  &.screenxxl{
    .man-wrapper {
      width: 950px;
      right: -14%;
      bottom: -2%;
      left: unset;
    }
    .video-container {
      width: 100%;
    }
  }

  .app-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 3;
  }

  &.screenmd .app-wrapper {
    padding: 20px 30px;
  }

  &.screenlg .app-wrapper,
  &.screenxl .app-wrapper,
  &.screenxxl .app-wrapper{
    padding: 30px 40px;
  }

  .text-wrapper h2 {
    font-size: 36px;
    font-family: 'CalibreBlack';
    text-transform: uppercase;
    margin: 0 0 15px;
    padding: 0;
    line-height: 1;
    font-weight: normal;
    color: white;
    @media(min-width: 390px) {
      font-size: 46px;
    }
  }

  .text-wrapper p {
    font-size: 15px;
    line-height: 1.5;
    margin-top: 0;
    color: white;
    @media(min-width: 390px) {
      font-size: 17px;
    }
  }

  &.screensm .text-wrapper {
    padding: 15px;
  }

  &.screenmd .text-wrapper {
    h2 {
      font-size: 50px;
    }
    p {
      width: 70%;
    }
  }

  &.screenlg .text-wrapper {
    h2 {
      font-size: 60px;
    }

    p {
      font-size: 20px;
      width: 70%;
    }
  }

  &.screenxl .text-wrapper {
    h2 {
      font-size: 72px;
    }

    p {
      font-size: 20px;
      width: 52%;
    }
  }

  &.screenxxl .text-wrapper {
    h2 {
      font-size: 72px;
    }

    p {
      font-size: 20px;
      width: 52%;
    }
  }


  .text-wrapper a.button {
    margin: 2rem auto;
    display: block;
  }

  &.screenmd .text-wrapper a.button,
  &.screenlg .text-wrapper a.button,
  &.screenxl .text-wrapper a.button,
  &.screenxxl .text-wrapper a.button{
    display: none;
  }


  .video-wrapper {
    position: relative;
    width: 100%;
    margin-top: 10px;
    .col-1, .col-2 {
      position: relative;
    }
  }
  &.screenmd .video-wrapper,
  &.screenlg .video-wrapper,
  &.screenxl .video-wrapper,
  &.screenxxl .video-wrapper{
    width: 100%;
    display: grid;
    grid-gap: 0;
    grid-template-columns: 60% 40%;
    grid-template-rows: auto;
  }

  .video-wrapper .col-1 .video-container {
      position: relative;
      video {
        cursor: pointer;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      .play-button {
        width: 100%;
        position: relative;
        z-index: 1;
        cursor: pointer;
      }
  }

  .video-wrapper .col-2 {
    display: flex;
    //justify-content: center;
    //align-items: center;
    margin-bottom: 4rem;
    padding-left: 15px;
    margin-top: 2rem;

    .ddinu-text {
      display: none;
    }
    .arrow-wrap {
      display: none;
    }
  }

  &.screenmd  .video-wrapper .col-2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 50px;
    flex-direction: row;
    margin-right: 0rem;
    justify-content: start;
    margin-bottom: -0.5rem;

    .arrow-wrap {
      z-index: 10;
      display: flex;
      order: 2;
      //transform: translateX(-20%) translateY(40%);
      position: absolute;
      bottom: -130px;
      right: 100%;
    }
    .link-wrapper {
      order: 3;
    }
    .ddinu-text {
      order: 1;
      display: block;
    }
  }

  &.screenlg  .video-wrapper .col-2,
  &.screenxl  .video-wrapper .col-2,
  &.screenxxl  .video-wrapper .col-2{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    margin-bottom: -0.5rem;
    .arrow-wrap {
      display: flex;
      z-index: 10;
      order: 1;
      position: absolute;
      bottom: -130px;
      right: 100%;
      transform: none;
    }
    .link-wrapper {
      order: 2;
    }
    .ddinu-text {
      display: none;
    }
  }

  .link-wrapper {
    a.button {
      display: none;
    }
  }
  &.screenmd .link-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
  }

  &.screenlg .link-wrapper,
  &.screenxl .link-wrapper,
  &.screenxxl .link-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &.screenmd .link-wrapper a.button,
  &.screenlg .link-wrapper a.button,
  &.screenxl .link-wrapper a.button,
  &.screenxxl .link-wrapper a.button{
    display: block;
  }
  .social {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    a {
      text-decoration: none;
      margin: 7px;
      img {
        max-width: 32px;
      }
    }
  }

  &.screenmd .social,
  &.screenlg .social,
  &.screenxl .social,
  &.screenxxl .social{
      flex-direction: row;
      justify-content: center;
      align-items: center;
  }

  a.button {
    background-color: $red;
    color: white;
    font-family: "CalibreBold", Arial;
    font-weight: normal;
    font-size: 17px;
    line-height: 1;
    text-transform: uppercase;
    padding: 14px 20px 14px 20px;
    text-align: center;
    text-decoration: none;
    @media(min-width: 390px) {
      padding: 14px 30px 14px 30px;
    }
    &:hover {
      background-color: $dark-red;
    }
  }

  .arrow-wrap {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    .img-wrap {
      position: relative;
      left: 150px;
      bottom: 40px;
      margin-bottom: 45px;
      animation: left-right-arrow 2s ease-in-out infinite;

      img {
        max-width: 130px;
      }
    }

    h2 {
      white-space: nowrap;
      display: inline-block;
      font-size: 25px;
      font-family: Anton;
      font-weight: normal;
      transform: translateY(-30%) rotate(-4deg);
      color: $yellow;
      text-transform: uppercase;
      text-decoration: underline;
    }
  }


  .bottom-wrapper {
    width: 100%;
    margin-bottom: .5em;
    text-align: center;
    p {
      margin: 0;
      padding: 0;
      font-family: "Calibre", Arial;
      font-size: 12px;
    }
  }
  &.screenmd .bottom-wrapper,
  &.screenlg .bottom-wrapper,
  &.screenxl .bottom-wrapper,
  &.screenxxl .bottom-wrapper{
    text-align: left;
    display: flex;
    align-items: center;
    margin-top: 2em;
    margin-bottom: 0;
    p {
      font-size: 14px;
    }
  }

  .mark {
    position: absolute;
    font-size: 17px;
    padding: 5px 13px;
    bottom: 20px;
    font-family: "Anton", Arial;
    display: inline-block;
    font-weight: normal;
    line-height: 1.5;
    text-transform: uppercase;
    background-color: $blue;
    color: $yellow;
    left: 0;
    z-index: 2;
  }

  &.screenmd .mark,
  &.screenlg .mark,
  &.screenxl .mark,
  &.screenxxl .mark{
    padding: 10px 20px 9px;
    font-size: 25px;
    left: -20px;
    bottom: 20px;
  }

  @keyframes left-right-arrow {
    0% {
      transform: translateX(0px);
    }
    40% {
      transform: translateX(30px);
    }
    60% {
      transform: translateX(30px);
    }
    100% {
      transform: translateY(0px);
    }
  }
}
</style>
